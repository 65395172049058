import { useRoutes, BrowserRouter } from 'react-router-dom';
import './App.css';
import Home from '../../Views/Home/Home';

const AppRoutes = () => {
  const routes = useRoutes([
    { path: '/', element: <Home /> }
  ]);
  return routes;
}

const App = () => {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
